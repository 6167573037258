.top-list {
  width: 100%;
}


.top-list-item-image {
  img {
    border-radius: 50%;
  }
}

.top-list-item-name {
  padding: 0 16px;
}
