.scoreboard {
  width: 100%;
}

.scoreboard-item-index {
  width: 32px;
  font-size: 28px;
}

.scoreboard-item-image {
  img {
    border-radius: 50%;
  }
}

.scoreboard-item-name {
  padding: 0 16px;

  em {
    font-size: 16px;
    color: #4F4F4F;
  }
}
