.single-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  line-height: 1;

  span {
    line-height: .7;
    font-weight: bold;
    font-size: 72px;
    display: block;
  }
}
