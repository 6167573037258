.list-item.zebra:nth-child(even) {
  background-color: #f5f5f5;
}

.list-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 8px;
  border-radius: 8px;

  &:hover {
    background-color: #e6e6e6;
  }
}

.list-item-grow {
  flex-grow: 1;
}

.list-item-no-grow {
  flex-grow: 0;
}
