@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;400;700&display=swap');

:root {
  // color-data-viz:
  --color-blue-100: #031324;
  --color-blue-90: #00284A;
  --color-blue-80: #004480;
  --color-blue-70: #005CA3;
  --color-blue-60: #1B7AC2;
  --color-blue-50: #00ACEC;
  --color-blue-40: #2FC8F7;
  --color-blue-30: #6CD5FF;
  --color-blue-20: #B3E6FF;
  --color-blue-10: #DEF4FA;
  --color-purple-100: #240024;
  --color-purple-90: #4A014A;
  --color-purple-80: #7A097A;
  --color-purple-70: #8F188F;
  --color-purple-60: #A13FA1;
  --color-purple-50: #CC50CC;
  --color-purple-40: #E670E6;
  --color-purple-30: #F291F2;
  --color-purple-20: #FAC5FA;
  --color-purple-10: #FAE3FA;
  --color-magenta-100: #2E0016;
  --color-magenta-90: #52032A;
  --color-magenta-80: #820D48;
  --color-magenta-70: #A8135D;
  --color-magenta-60: #C2347B;
  --color-magenta-50: #E64997;
  --color-magenta-40: #FF6BB5;
  --color-magenta-30: #FF94C9;
  --color-magenta-20: #FFC7E4;
  --color-magenta-10: #FFE3F0;
  --color-teal-100: #001612;
  --color-teal-90: #082823;
  --color-teal-80: #16453D;
  --color-teal-70: #195C50;
  --color-teal-60: #3B786E;
  --color-teal-50: #3DA090;
  --color-teal-40: #5ABFAE;
  --color-teal-30: #7ED6C6;
  --color-teal-20: #B4EAE1;
  --color-teal-10: #D7F7F3;
  --color-green-100: #011900;
  --color-green-90: #10290E;
  --color-green-80: #1A4717;
  --color-green-70: #1E6119;
  --color-green-60: #477A43;
  --color-green-50: #51A34B;
  --color-green-40: #75BA70;
  --color-green-30: #98D694;
  --color-green-20: #BFEDBB;
  --color-green-10: #E2F7DF;
  --color-yellow-100: #111100;
  --color-yellow-90: #232101;
  --color-yellow-80: #3A3813;
  --color-yellow-70: #524C00;
  --color-yellow-60: #756E09;
  --color-yellow-50: #968D0A;
  --color-yellow-40: #B5AA0D;
  --color-yellow-30: #D6C918;
  --color-yellow-20: #EDE56F;
  --color-yellow-10: #FAF5B6;
  --color-gold-100: #1A0E00;
  --color-gold-90: #331A02;
  --color-gold-80: #542C04;
  --color-gold-70: #753B00;
  --color-gold-60: #A65503;
  --color-gold-50: #C17120;
  --color-gold-40: #D98F45;
  --color-gold-30: #F2AC66;
  --color-gold-20: #FFD3A8;
  --color-gold-10: #FFEDDB;
  --color-orange-100: #290700;
  --color-orange-90: #4D0D00;
  --color-orange-80: #751A08;
  --color-orange-70: #A82005;
  --color-orange-60: #D93616;
  --color-orange-50: #ED5739;
  --color-orange-40: #F57C64;
  --color-orange-30: #FF9F8C;
  --color-orange-20: #FFCEC4;
  --color-orange-10: #FFEAE8;
  --color-red-100: #3E0000;
  --color-red-90: #5C0006;
  --color-red-80: #A30005;
  --color-red-70: #CC0815;
  --color-red-60: #E12E2E;
  --color-red-50: #FF4754;
  --color-red-40: #FF757E;
  --color-red-30: #FF99A0;
  --color-red-20: #FFC9CD;
  --color-red-10: #FFE8E9;
  // color-grey:
  --color-grey-100: #0F0F0F;
  --color-grey-90: #1F1F1F;
  --color-grey-80: #333333;
  --color-grey-70: #464646;
  --color-grey-60: #696969;
  --color-grey-50: #868686;
  --color-grey-40: #9F9F9F;
  --color-grey-30: #BABABA;
  --color-grey-20: #DBDBDB;
  --color-grey-10: #EBEBEB;
  // color-white:
  --color-white-primary: #FFFFFF;
  --color-white-secondary: #FAFAFA;
  --color-white-tertiary: #F5F5F5;
  // color-black:
  --color-black-primary: #000000;
  --color-black-secondary: #050505;
  --color-black-tertiary: #0A0A0A;
  // color-status:
  --color-status-red: #F03040;
  --color-status-magenta: #CB2BD5;
  --color-status-orange: #FF7300;
  --color-status-blue: #0052FF;
  --color-status-yellow: #FFD800;
  --color-status-green: #0CA919;
}

*, :after, :before {
  box-sizing: border-box;
}

body {
  font-family: 'Rokkitt', serif;
  font-size: 20px;
  margin: 0;
  line-height: 1.6;
}

.wrapper {
  padding: 64px 0;

  &--inline {
    margin: 32px 128px;
  }

  &--light-blue {
    background-color: var(--color-blue-30);
    color: var(--color-white-primary);
  }

  &--blue {
    background-color: var(--color-blue-70);
    color: var(--color-white-primary);
  }

  &--light-gray {
    background-color: var(--color-grey-10);
    color: var(--color-blue-80);
  }

  &--black {
    background-color: var(--color-grey-90);
    color: var(--color-white-primary);

    .recharts-tooltip-wrapper {
      color: #aaa;
    }
  }

  &--white {
    background-color: var(--color-white-tertiary);
    color: var(--color-blue-80);
  }

}

footer {
  background-color: var(--color-grey-20);
  padding: 36px 0;

  div {
    width: 960px;
    margin: 0 auto;
    text-align: center;
    color: var(--color-grey-40);
  }
}

.container {
  margin: 0 auto;
  width: 100%;

  &--960 {
    width: 960px;
  }

  &--full {
    width: 90%;
  }
}

.section {
  h2 {
    font-size: 48px;
    margin: 0;
  }
}

.year-overview-stats {
  display: flex;
  align-items: center;

  > h1 {
    font-size: 120px;
    margin: 0;
    line-height: 1;
  }

  .single-stats-container {
    flex-grow: 1;
    display: flex;
    gap: 80px;
    justify-content: flex-end;
  }
}

.user-page-header {
  > h1 {
    font-size: 120px;
    margin: 0;
    line-height: 1;
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      margin-right: 32px;
    }
  }
}

.top-list-container {
  display: flex;
  gap: 80px;
}

a {
  text-decoration: none;
  color: var(--color-gold-50);

  &:hover {
    text-decoration: underline;
  }
}

img {
  display: block;
}

.highcharts-container {
  font-family: 'Rokkitt', serif;
}

.highcharts-credits { display: none; }
.highcharts-background { fill: transparent; }

