.section--rolls-by-points {
  .highcharts-container {
    font-size: 20px;
  }

  .highcharts-data-label text {
    fill: var(--color-white-primary);
  }

  .highcharts-xaxis-labels {
    fill: var(--color-white-primary);
    font-size: 20px;
    font-weight: bold;
  }

  .highcharts-column-series rect.highcharts-point {
    fill: var(--color-blue-50);
    stroke: none;
  }

  .highcharts-axis-line,
  .highcharts-tick {
    display: none;
  }
}
