.roll-list {
  width: 100%;

  .date-info {
    background-color: var(--color-gold-40);
    padding: 4px 8px;
    border-radius: 4px;
    color: var(--color-white-primary);
    margin-bottom: 32px;
  }
}

.roll-list .list-item.roll-list-item--warning {
  background-color: #F58C91;
  color: #FFFFFF;

  em {
    padding: 0 16px;
    font-size: .8em;
  }
}
