div.user-select-container {
  position: sticky;
  top: 1em;
  z-index: 2;

  .user-select__multi-value {
    background-color: var(--color-blue-80);
    border-radius: 4px;

    &__label {
      color: var(--color-white-secondary);
      padding: 4px;
    }

    &__remove {
      color: var(--color-white-secondary);

      &:hover {
        background-color: var(--color-red-50);
        color: var(--color-white-secondary);
      }
    }
  }

  .user-select__option {
    &:active,
    &--is-focused {
      background-color: var(--color-grey-20);
    }
  }
}

.user-select-user-badge {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  img {
    border-radius: 50%;
    margin-right: 16px;
    width: 24px;
  }
}
