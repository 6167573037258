nav {
  background-color: var(--color-white-primary);
  margin-bottom: 8px;
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    color: #FFFFFF;
  }

  li {
    &:not(:last-child) {
      margin-right: 8px;
    }

    a {
      display: block;
      color: var(--color-grey-50);
      padding: 8px 16px;
      font-weight: bold;
      border-bottom: 4px solid var(--color-white-primary);



      &:hover {
        text-decoration: none;
        border-bottom: 4px solid var(--color-grey-60);
      }

      &.active {
        border-bottom: 4px solid var(--color-gold-50);
      }
    }
  }
}
